@import "src/containers/theme/base";

.par-ecom-widget.widget--login {
    .par-ecom-widget__header-title {
        margin: 0 auto;
        max-width: calc(100% - 110px);
        text-align: center;
    }
}

.par-ecom-widget.widget--login {
    .par-ecom-widget__main {
        text-align: center;
    }
}

.par-ecom-widget__login-title {
    font-family: $ff-publicsans-r;
    font-size: $fs-small;
    font-weight: 400;
    line-height: 18px;
    margin: 0 auto;
    max-width: 210px;
    padding: 20px 0;
}

.par-ecom-widget.widget--login {
    .btn--filled {
        margin: 20px 0 30px;
    }
}

.par-ecom-widget__help-text {
    display: block;
    font-size: $fs-small;
    line-height: 18px;
}

.par-ecom-widget.widget--login {
    .btn--outlined {
        margin: 15px 0 50px;
    }
}