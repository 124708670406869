/* Header */
.par-ecom-widget__header {
    border-radius: 8px 8px 0 0;
    padding: 18px 12px 18px 12px;
    position: relative;
}

.par-ecom-widget:not(.widget--login) {
    .par-ecom-widget__header {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding-right: 30px;

        .par-ecom-widget__header-title {
            max-width: 180px;
            margin-right: 8px;
        }

        .par-ecom-widget__header-ls,
        .par-ecom-widget__header-faq {
            max-width: 60px;
            margin: 0 8px 8px 0;
            margin-bottom: 10px;
        }

        .par-ecom-widget__header-faq {
            margin-right: 0;
        }

        .par-ecom-widget--close {
            position: absolute;
            top: 18px;
            right: 12px;
        }
    }
}

.par-ecom-widget__header-title {
    font-size: $fs-normal;
    line-height: 22px;
    margin-bottom: 12px;

    &>span {
        display: block;
    }
}

.par-ecom-widget.widget--login {
    .par-ecom-widget__icons {
        position: absolute;
        right: 8px;
        top: 8px;
    }
}

.par-ecom-widget.widget--login {
    .par-ecom-widget__header-title {
        font-size: $fs-large;
        line-height: 28px;
    }
}

.par-ecom-widget__header-ls {
    select {
        background-color: rgba($c-black, 0.24);
        border-radius: 10px;
        border: none;
        color: $c-white;
        cursor: pointer;
        display: inline-block;
        font-size: $fs-small;
        line-height: 12px;
        padding: 3px 2px;

        &:focus {
            border: none;
            outline: none;
        }
    }
}

.par-ecom-widget__header-faq {
    align-self: flex-start;
    background-color: rgba($c-black, 0.24);
    border-radius: 10px;
    cursor: pointer;
    display: inline-block;
    max-height: 22px;
    padding: 4px 8px;
    text-align: center;

    .par-ecom-widget__header-faq-icon {
        display: inline-block;
        height: 13px;
        vertical-align: text-top;
        width: 13px;
        margin-right: 5px;
    }

    .par-ecom-widget__header-faq-text {
        color: $c-white;
        display: inline-block;
        font-family: $ff-publicsans-b;
        font-size: $fs-xx-small;
        line-height: 10px;
        padding-top: 1px;
        vertical-align: text-top;
    }
}

.par-ecom-widget--close {
    background: rgba($c-black, 0.24);
    border-radius: 100%;
    cursor: pointer;
    display: inline-block;
    font-size: $fs-large;
    height: 20px;
    line-height: 1;
    text-align: center;
    width: 20px;
    padding: 6px;
}