/*==========================================================
=            Typography Variable and Declaration            =
============================================================*/

@font-face {
    font-family: 'PublicSans-Bold';
    src: url('./fonts/PublicSans-Bold.ttf');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PublicSans-SemiBold';
    src: url('./fonts/PublicSans-SemiBold.ttf');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PublicSans-Medium';
    src: url('./fonts/PublicSans-Medium.ttf');
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'PublicSans-Regular';
    src: url('./fonts/PublicSans-Regular.ttf');
    font-style: normal;
    font-display: swap;
}

/*----------  Font Family Variable  ----------*/

$ff-publicsans-b: 'publicSans-Bold';
$ff-publicsans-m: 'publicSans-Medium';
$ff-publicsans-sb: 'publicSans-SemiBold';
$ff-publicsans-r: 'publicSans-Regular';

/*----------  Font Size Variable  ----------*/

$fs-large : 18px;
$fs-medium : 16px;
$fs-normal : 14px;
$fs-small : 12px;
$fs-x-small : 10px;
$fs-xx-small : 8px;


/*=====  End of Typography Variable and Declaration  ======*/