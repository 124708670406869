.par-ecom-widget__table {
    table {
        text-align: center;
        width: 100%;

        th {
            border-bottom: 1px solid rgba($c-cadet-grey, 0.16);
            font-family: $ff-publicsans-r;
            font-size: $fs-small;
            line-height: 18px;
            padding: 5px;
        }

        td {
            font-family: $ff-publicsans-r;
            font-size: $fs-x-small;
            line-height: 12px;
            padding: 5px;
        }
    }
}