.par-ecom-widget {
    &.modal--open::before {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
    }
}

.par-ecom-widget {
    &.fwidget__code::before {
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
    }
}

.par-ecom-widget__modal {
    border-radius: 8px;
    bottom: 0;
    left: 0;
    padding: 8px 20px 25px;
    position: fixed;
    right: 0;
    transform: translateY(100%);
    transition: all 0.3s linear;
    z-index: 999;

    &.modal-order--open,
    &.modal-bday--open,
    &.modal-discount--open,
    &.active-reward-discount--open {
        transform: translateY(0);
    }
}

.par-ecom-widget__modal-title {
    font-family: $ff-publicsans-sb;
    font-size: $fs-normal;
    line-height: 18px;
    margin-bottom: 10px;
}

.par-ecom-widget__modal-close {
    cursor: pointer;
    display: inline-block;
    height: 8px;
    position: absolute;
    right: 20px;
    top: 14px;
    width: 8px;
}

.par-ecom-widget__modal-content {
    p {
        font-size: $fs-small;
        line-height: 18px;
    }
}

.modal-order {
    .par-ecom-widget__modal-content {
        p {
            text-align: center;
        }
    }
}


/* Active Reward Discount Modal */

.par-ecom-widget__modal {
    &.modal-acitive-reward__discount {
        padding: 8px 10px 20px;
        bottom: 20px;

        .par-ecom-widget__modal-close {
            right: 10px;
        }
    }
}