/* Reward Icon CSS Start*/
.error {
    color: red;
}

.success {
    color: green;
}

.par-ecom-widget__rewards {
    max-width: 50px;
    //margin-left: auto;

    .par-ecom-widget__rewards-icon {
        border-radius: 8px;
        cursor: pointer;
        display: inline-block;
        padding: 10px 13px;
        width: 48px;
        height: 48px;
    }
}

.widget-wrapper {
    border-radius: 8px;
    bottom: 0;
    height: 100%;
    max-width: 400px;
    position: fixed;
    right: 0;
    width: 100%;
}

.par-ecom {
    height: 100%;
    width: 100%;
    margin: 20px 10px;
    max-height: calc(100% - 40px);
    max-width: calc(100% - 20px);
    position: relative;
}

.par-ecom-widget {
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba($c-black, 0.12);
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
   //transform-origin: bottom right;
    transform: scale(0);
    transition: all 0.2s linear;

}

.par-ecom__container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    &.widget--open {
        .par-ecom-widget {
            transform: scale(1);
        }
    }
}

.text--bold {
    font-weight: bold;
}

/* Body CSS Start*/

.par-ecom-widget__main {
    border-radius: 0 0 8px 8px;
    padding: 0 0 20px;
}

.par-ecom-widget__container {
    height: 320px;
    overflow-y: auto;
    padding: 35px 12px 15px;

    &::-webkit-scrollbar {
        border-radius: 0 0 10px 10px;
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        width: 6px;
    }
}