.par-ecom-btn {
    border-radius: 8px;
    display: inline-block;
    font-family: $ff-publicsans-b;
    font-size: $fs-normal;
    line-height: 26px;
    min-width: 180px;
    padding: 10px 12px;
    text-align: center;
}

.btn--outlined {
    background-color: transparent;
}

.par-ecom__btn-redeem {
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    font-family: $ff-publicsans-b;
    font-size: $fs-x-small;
    line-height: 18px;
    padding: 6px;
    text-align: center;
}