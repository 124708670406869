@import "src/containers/theme/base";

.par-ecom__loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;


    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        border-radius: 8px;
        top: 0;
        bottom: 0;
        background-color: rgba($c-black, 0.45);
        z-index: 999;
    }
}

.par-ecom__loader-container {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.par-ecom__loader-spin {
    display: inline-block;
    height: 37px;
    position: relative;
    width: 15px;

    &:before {
        animation: bounce 500ms alternate infinite ease;
        border-radius: 50%;
        content: '';
        display: block;
        height: 15px;
        position: absolute;
        top: 0;
        transform-origin: 50%;
        width: 15px;
    }
}

.par-ecom__loader-text {
    display: inline-block;
    font-family: $ff-publicsans-m, sans-serif;
    font-size: $fs-normal;
    font-weight: 600;
    line-height: 1;
    margin-left: 10px;
}

@keyframes bounce {
    0% {
        border-radius: 60px 60px 20px 20px;
        height: 5px;
        top: 30px;
        transform: scaleX(2);
    }

    35% {
        border-radius: 50%;
        height: 15px;
        transform: scaleX(1);
    }

    100% {
        top: 0;
    }
}