/* Nav Tab */
.par-ecom-widget__nav-tab {
    display: none;

    &.nav-tab--active {
        display: block;
    }
}

.par-ecom-widget__nav {
    position: relative;

    .par-ecom-widget__nav-list {
        border-radius: 20px;
        box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12);
        display: flex;
        flex-wrap: wrap;
        left: 15px;
        list-style: none;
        margin: 0;
        padding: 5px;
        position: absolute;
        right: 15px;
        top: -20px;
        z-index: 9;

        &>li {
            flex: 0 0 100%;
            max-width: calc(33.33% - 6px);
        }

        &>li:not(:last-child) {
            margin-right: 8px;
        }
    }
}



.par-ecom-widget__nav-link {
    background-color: transparent;
    border-radius: 20px;
    display: block;
    font-size: $fs-x-small;
    line-height: 18px;
    min-width: 75px;
    padding: 6px 8px;
    text-align: center;
    z-index: 999;
}