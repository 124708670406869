@import "src/containers/theme/base";
/* Home css */

.nav-tab__home {
    display: flex;
    overflow: hidden;

    .nav-tab__home-container--one {
        flex: 0 0 100%;
        max-width: 100%;
        transition: all 0.3s linear;
    }

    .nav-tab__home-container--two,
    .nav-tab__home-container--three {
        flex: 0 0 100%;
        max-width: 100%;
        transform: translateX(100%);
        transition: all 0.3s linear;

    }
}

.par-ecom-widget__nav-tab {
    &.active-reward--show {
        .nav-tab__home-container--one {
            transform: translateX(-100%);
        }

        .nav-tab__home-container--two {
            transform: translateX(-100%);
        }
    }
}

.par-ecom-widget__nav-tab {
    &.your-activity--show {
        .nav-tab__home-container--one {
            transform: translateX(-100%);
        }

        .nav-tab__home-container--three {
            transform: translateX(-200%);
        }
    }
}

.nav-tab__home-container--one {
    .par-ecom-widget__list {
        border-bottom: 1px solid rgba(145, 158, 171, 0.32);
    }
}

.par-ecom-widget__list-item {
    align-items: center;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 30px auto 20px;
    margin-bottom: 16px;
    padding: 6px 10px;
}

.par-ecom-widget__list-text {
    display: inline-block;
    font-size: $fs-small;
    line-height: 14px;
    margin: 0 8px;
}

.par-ecom-widget__list-trailing-icon {
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

.par-ecom-widget__label {
    font-size: $fs-small;
    font-family: $ff-publicsans-m, sans-serif;
    line-height: 18px;
    margin-bottom: 8px;
}

.par-ecom-widget__content {
    padding: 16px 0 0;

    p {
        font-size: $fs-x-small;
        margin-bottom: 10px;
        line-height: 10px;
    }
}

/* Active Reward and Your Activity CSS Start*/
.par-ecom-widget__active-reward-title,
.par-ecom-widget__your-activity-title {
    display: grid;
    grid-template-columns: 10px auto;
    gap: 15px;
    margin-bottom: 12px;

    &>span {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        transform: rotate(-180deg) translateY(6px);
    }

    &>h2 {
        font-size: $fs-normal;
        font-family: $ff-publicsans-m;
        line-height: 24px;
    }
}


/* Earn Points CSS */
.par-ecom-widget__list-content {
    margin: 0 10px;
}

.par-ecom-widget__list-label {
    font-size: $fs-small;
    line-height: 16px;
    display: block;
}

.par-ecom-widget__list-help-text {
    font-size: $fs-x-small;
    line-height: 12px;
    display: block;
}

/* Rewards CSS */

.nav-tab--rewards {
    .par-ecom-widget__list-item {
        grid-template-columns: 30px auto 60px;
    }
}

.par-ecom-widget__code {
    display: none;

    &.code--show {
        display: block;
    }

    .par-ecom-widget__code-wrap {
        display: grid;
        grid-template-columns: 15px auto 15px;
        padding: 4px;
        border-radius: 4px;
        text-align: center;
        align-items: center;
        margin-top: 50px;

        .par-ecom-widget__code-text {
            font-size: $fs-x-small;
            line-height: 12px;
        }

        .par-ecom-widget__code-close-icon {
            display: inline-block;
            cursor: pointer;
            text-align: center;
        }
    }
}


/* FAQ's CSS start */

.par-ecom-widget__faq {
    &-title {
        font-size: $fs-normal;
        line-height: 18px;
        margin-bottom: 15px;
    }
}

.par-ecom-widget__faq-wrap {
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 10px;

    .par-ecom-widget__faq-que {
        font-size: $fs-small;
        line-height: 14px;
        margin-bottom: 4px;
    }

    .par-ecom-widget__faq-ans {
        p {
            font-size: $fs-x-small;
            line-height: 12px;
        }
    }
}