.par-ecom__referral-form-group,
.par-ecom-widget__modal-form-group {
    position: relative;
}

/*  Birthday Modal */

.par-ecom-widget__modal-form {
    &.form-bday {
        .par-ecom-widget__modal-form-field {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

    }
}

.par-ecom-widget__modal-form-group {
    label {
        display: inline-block;
        font-size: $fs-x-small;
        line-height: 18px;
        margin-bottom: 5px;
    }

    input[type="date"] {
        border-radius: 8px;
        border: none;
        font-size: $fs-x-small;
        line-height: 18px;
        max-width: calc(100% - 60px);
        padding: 6px 10px;
        width: 100%;
    }

    input[type="submit"] {
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-family: $ff-publicsans-b;
        font-size: $fs-x-small;
        line-height: 18px;
        padding: 6px 10px;
    }
}

/* Discount Form */

.par-ecom-widget__modal-form-field {
    position: relative;
}

.par-ecom__referral-form-group,
.form-discount .par-ecom-widget__modal-form-field,
.form-acitive-reward__discount .par-ecom-widget__modal-form-field {
    input[type="text"] {
        border-radius: 8px;
        border: none;
        font-size: $fs-x-small;
        line-height: 18px;
        overflow: hidden;
        padding: 6px 65px 6px 13px;
        text-overflow: ellipsis;
        width: 100%;

        &:focus {
            outline : none;
        }
    }

    input[type="submit"] {
        border-radius: 8px;
        border: none;
        cursor: pointer;
        font-family: $ff-publicsans-b;
        font-size: $fs-x-small;
        line-height: 18px;
        min-width: 60px;
        padding: 6px 10px;
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
    }
}