@import "colors", "typography", "button", "form", "table", "common", "header",
"nav-tab", "modal";

/*=============================================
=            Global CSS            =
=============================================*/
*,
*::after,
*::before {
    box-sizing: border-box;
}

html,
body,
p {
    font-family: $ff-publicsans-r, sans-serif;
    font-size: $fs-x-small;
    letter-spacing: 0.5px;
    line-height: 14px;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;

    &:hover,
    &:focus {
        outline: none;
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $ff-publicsans-r, sans-serif;
    letter-spacing: 0.5px;
    margin: 0;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}